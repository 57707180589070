<template>
	<div class="l24TraConter s24uuskr hoverTst" style="width: 100%; min-width: 100%">
		<!-- 아무값없을때 미리보기에서만 -->
		<div
			v-if="init_load && (!store.shorts?.length || store.shorts?.length < 1) && wstore.preview"
			:style="{
				paddingTop: wstore.marginTop,
				paddingBottom: wstore.marginBottom,
				paddingLeft: wstore.mobile ? 0 : wstore.marginLeft,
				paddingRight: wstore.mobile ? 0 : wstore.marginRight,
				maxWidth: '100%',
				backgroundColor: wstore.backgroundColor,
			}"
		>
			<div
				:style="{
					gap: wstore.mobile ? 0 : wstore.cardGap,
				}"
				style="display: flex; flex-direction: row"
			>
				<div
					v-for="(pp, idx) in sklCnt"
					:style="{
						minWidth: wstore.mobile
							? `calc((${wstore.maxWidth} / ${wstore.colspan}) + (${wstore.cardGap}  * 1) )`
							: `calc((${wstore.maxWidth} / ${wstore.colspan}) - (${wstore.cardGap}  * 1) + (${wstore.cardGap} / ${wstore.colspan})  )`,
					}"
					style="scroll-snap-align: start; max-width: 100%; max-height: 100%"
				>
					<div
						:style="{
							paddingLeft: wstore.mobile ? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px` : 0,
							paddingRight: wstore.mobile ? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px` : 0,
						}"
					>
						<div
							:style="{
								aspectRatio: wstore.aspectRatio,
							}"
							style="width: 100%"
						>
							<UiSkl
								:borderRadius="wstore.borderRadius"
								height="100%"
								style="margin-bottom: 10px"
								width="100%"
							/>
						</div>

						<div style="padding-top: 8px">
							<UiSkl borderRadius="10px" height="20px" style="margin-bottom: 10px" width="100%"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 아무값없을때 미리보기에서만 -->
		<div
			v-show="store.shorts?.length > 0"
			:style="{
				backgroundColor: wstore.backgroundColor,
			}"
			class="hoverTst"
			style="width: 100%"
		>
			<div v-if="init_load">
				<div
					:style="{
						paddingTop: wstore.marginTop,
						paddingBottom: wstore.marginBottom,
						paddingLeft: wstore.mobile ? 0 : wstore.marginLeft,
						paddingRight: wstore.mobile ? 0 : wstore.marginRight,
						maxWidth: wstore.maxWidth,
					}"
					style="margin: auto"
				>
					<div
						v-if="wstore.title"
						:style="{ paddingLeft: wstore.mobile ? '0px' : '' }"
						style="
							margin: 0 0 25px 0;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
						"
					>
						<strong
							:style="{
								fontSize: wstore.titleFontSize,
								color: wstore.textColor,
								paddingLeft: wstore.mobile ? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px` : 0,
								paddingRight: wstore.mobile ? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px` : 0,
							}"
							class="ft16 vodCardTitle"
						>
							{{ wstore.title }}
						</strong>

						<div v-if="wstore.moreLink && wstore.moreText" style="text-align: right">
							<button
								:style="{
									fontSize: wstore.moreFontSize,
									color: wstore.textColor,
								}"
								style="opacity: 0.5; background-color: transparent; border: 0"
								@click="goLink(wstore.moreLink)"
							>
								{{ wstore.moreText }}
							</button>
						</div>
					</div>

					<div>
						<div :class="wstore.colspan <= 3 ? '' : 'cardViewWrap'" class="w-full">
							<div
								id="short-card-list"
								:style="{ maxWidth: wstore.mobile ? '100%' : wstore.maxWidth, width: wstore.maxWidth }"
								class=""
								style="position: relative"
							>
								<div
									:id="`${instantId}_scbody`"
									:style="{ maxWidth: wstore.maxWidth, marginLeft: wstore.mobile ? '0px' : '' }"
									class="overflow-auto noscroll shortCardItemsd"
									style="-webkit-overflow-scrolling: touch"
								>
									<div
										:style="{
											gap: wstore.mobile ? 0 : wstore.cardGap,
										}"
										style="display: flex; flex-direction: row"
									>
										<div
											v-for="(p, idx) in store.shorts"
											:id="`${instantId}_${idx + 1}`"
											id="shortslist"
											:style="{
												minWidth: wstore.mobile
													? `calc((${wstore.maxWidth} / ${wstore.colspan}) + (${wstore.cardGap}  * 1) )`
													: `calc((${wstore.maxWidth} / ${wstore.colspan}) - (${wstore.cardGap}  * 1) + (${wstore.cardGap} / ${wstore.colspan})  )`,
											}"
											style="scroll-snap-align: start; max-width: 100%; max-height: 100%"
										>
											<div
												:style="{
													paddingLeft: wstore.mobile
														? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px`
														: 0,
													paddingRight: wstore.mobile
														? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px`
														: 0,
												}"
											>
												<shortsCard
													:p="p"
													:preview="
														store.previewShorts
															? store.previewShorts.find(
																	(preview) => preview.content_no == p.content_no
															  )
															: null
													"
													:userNo="userNo"
													:userType="userType"
													:wstore="wstore"
												/>
											</div>
										</div>

										<div
											v-for="(pp, idx) in sklCnt"
											:id="`${instantId}_${idx + 1}`"
											:style="{
												minWidth: wstore.mobile
													? `calc((${wstore.maxWidth} / ${wstore.colspan}) + (${wstore.cardGap}  * 1) )`
													: `calc((${wstore.maxWidth} / ${wstore.colspan}) - (${wstore.cardGap}  * 1) + (${wstore.cardGap} / ${wstore.colspan})  )`,
											}"
											style="scroll-snap-align: start; max-width: 100%; max-height: 100%"
										>
											<div
												:style="{
													paddingLeft: wstore.mobile
														? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px`
														: 0,
													paddingRight: wstore.mobile
														? `${parseInt(wstore.cardGap.replace('px', '')) / 2}px`
														: 0,
												}"
											>
												<div
													:style="{
														aspectRatio: wstore.aspectRatio,
													}"
													style="width: 100%"
												>
													<UiSkl
														:borderRadius="wstore.borderRadius"
														height="100%"
														style="margin-bottom: 10px"
														width="100%"
													/>
												</div>

												<div style="padding-top: 8px">
													<UiSkl
														borderRadius="10px"
														height="20px"
														style="margin-bottom: 10px"
														width="100%"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									:class="{
										isProduct: wstore.product == 'Y',
									}"
									class="hoverVst"
								>
									<div v-if="scrollTab > 1" class="swiper-button-prev" @click="prev">
										<img
											alt=""
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202311/488111047152155119373963ab6bd7d8.svg"
										/>
									</div>
									<div
										v-if="
											wstore.orderCol != 'custom'
												? (scrollTab < wstore.limit && shortsTotal > wstore.colspan) ||
												  scrollTab < shortsTotal
												: scrollTab < shortsTotal
										"
										class="swiper-button-next"
										@click="next"
									>
										<img
											alt=""
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202311/bcf37c780fd8c3a8c6c4583183c6e0f7.svg"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isUnPaid" style="background: black; width: 100%; height: 400px"></div>
	</div>
</template>

<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref} from "vue";
import shortsCard from "./ui/shortsCard.vue";

import {useL24Store} from "../store/l24";
import UiSkl from "./ui/skl.vue";

const store = useL24Store();
const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "20px",
	marginBottom: "20px",
	marginLeft: "0",
	marginRight: "0",
	maxWidth: "980px",
	title: "",
	titleFontSize: "20px",
	cardGap: "10px",
	colspan: 4,
	limit: 12,
	backgroundColor: "#ffffff",
	textColor: "#000000",
	orderCol: "no",
	mobile: false,
	aspectRatio: "9/12",
	moreText: "더보기",
	moreFontSize: "24px",
	moreLink: "",
	isPreview: "N",
	product: "N",
	productType: "",
	customShorts: "",
});

let init_load = ref(false);
let scrollTab = ref(1);
let userNo = ref(null);
let userType = ref(null);
const isAllow = ref(true);
const isUnPaid = ref(false);

const generateRandomString = (num) => {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let result = "";
	const charactersLength = characters.length;
	for (let i = 0; i < num; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
};

const instantId = ref(generateRandomString(10));

const next = () => {
	try {
		if (scrollTab.value < shortsTotal.value) {
			scrollTab.value++;

			if (scrollTab.value > shortsTotal.value - wstore.value.colspan) {
				scrollTab.value = shortsTotal.value;
			}

			let tbo = document.getElementById(`${instantId.value}_scbody`);
			let leftPos = tbo.scrollLeft + tbo.clientWidth / wstore.value.colspan;

			document.getElementById(`${instantId.value}_scbody`).scrollTo({
				left: leftPos,
				behavior: "smooth",
			});
		}
	} catch (e) {
	}
};

const prev = () => {
	try {
		if (scrollTab.value > 1) {
			if (scrollTab.value == shortsTotal.value) {
				scrollTab.value = scrollTab.value - wstore.value.colspan;
			} else {
				scrollTab.value--;
			}
			let tbo = document.getElementById(`${instantId.value}_scbody`);
			let leftPos = tbo.scrollLeft - tbo.clientWidth / wstore.value.colspan;

			document.getElementById(`${instantId.value}_scbody`).scrollTo({
				left: leftPos,
				behavior: "smooth",
			});
		}
	} catch (e) {
	}
};
//computed()
const config = computed(() => store.l24config || "");

const shortsTotal = computed(() => {
	try {
		return store.shorts.length;
	} catch (e) {
		return 0;
	}
});
const sklCnt = computed(() => {
	try {
		let cnt = wstore.value.colspan - store.shorts.length;
		if (cnt > 0) {
			return cnt;
		} else {
			return 0;
		}
	} catch (e) {
		return wstore.value.colspan;
	}
});

const setOptions = async (options) => {
	let wCwidth = document.querySelector(".s24uuskr").clientWidth;

	if (wCwidth < 768) {
		props.options.mobile = true;
	}

	let reload = false;
	try {
		if (options.limit) {
			if (options.limit != wstore.value.limit) {
				reload = true;
			}
		}

		if (options.orderCol) {
			if (options.orderCol != wstore.value.orderCol) {
				reload = true;
			}
		}
	} catch (e) {
	}

	wstore.value = {...wstore.value, ...options};

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	if (window.innerWidth < 768 || wCwidth < 768) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = "100%";
	} else {
		if (wstore.value.mobile) {
			wstore.value.colspan = 2;
			if (document.querySelector(".s24uuskr").clientWidth > 40) {
				wstore.value.maxWidth = document.querySelector(".s24uuskr").clientWidth + "px";
			}
		}
	}

	if (reload) {
		await store.getVodCardList({
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			cache_time: 5,
			no_product: wstore.value.product == "Y" ? "" : "Y",
		});
	}
};

const goLink = (link) => {
	if (wstore.value.privew) {
		alert("미리보기에서는 링크가 동작하지 않습니다.");
	} else {
		location.href = link;
	}
};

defineExpose({
	setOptions,
});

onUnmounted(() => {
	window.removeEventListener("resize", () => {
	});
	// observer.disconnect();
});

// const observer = new IntersectionObserver(
// 	(entries, observer) => {
// 		entries.forEach((entry) => {
// 			if (entry.isIntersecting) {
// 				entry.target.muted = true;
// 				entry.target.play();
// 			} else {
// 				entry.target.muted = true;
// 				entry.target.pause();
// 			}
// 		});
// 	},
// 	{
// 		root: null,
// 		rootMargin: "0px",
// 		threshold: 0.5,
// 	}
// );

const resizeRun = () => {
	if (props.options.mobile) {
		wstore.value.mobile = true;
		wstore.value.colspan = 2;
		wstore.value.maxWidth = document.querySelector(".s24uuskr").clientWidth + "px";
	} else {
		if (window.innerWidth < 768) {
			wstore.value.mobile = true;
			wstore.value.colspan = 2;
			wstore.value.maxWidth = window.innerWidth - 20 + "px";
		} else {
			wstore.value.mobile = false;
			wstore.value.colspan = props.options.colspan;
			wstore.value.maxWidth = props.options.maxWidth;
			wstore.value.marginLeft = props.options.marginLeft;
			wstore.value.marginRight = props.options.marginRight;
		}
	}
};

//mounted()
onMounted(async () => {
	let wCwidth = document.querySelector(".s24uuskr").clientWidth;

	if (wCwidth < 768) {
		props.options.mobile = true;
	}

	//계정 환경설정 로드
	await store.getL24Config();
	if (store.l24config) {
		userNo.value = store.l24config.user_no;
		userType.value = store.l24config.type;
	} else {
		userNo.value = props.options.userNo;
		userType.value = props.options.type;
	}

	wstore.value = {...wstore.value, ...props.options};

	wstore.value.colspan = parseInt(wstore.value.colspan);
	wstore.value.limit = parseInt(wstore.value.limit);

	setTimeout(() => {
		if (window.innerWidth < 768 || wCwidth < 768) {
			wstore.value.mobile = true;
			wstore.value.colspan = 2;
			wstore.value.maxWidth = window.innerWidth + "px";
		} else {
			if (wstore.value.mobile) {
				wstore.value.colspan = 2;
				wstore.value.maxWidth = document.querySelector(".s24uuskr").clientWidth + "px";
			}
		}

		resizeRun();
	}, 100);

	const unPaidResult = await store.getUserUnPaidInfo({
		user_no: userNo.value,
	});

	if (unPaidResult.result.deleted || unPaidResult.result.restricted) {
		isUnPaid.value = true;
	}

	if (props.options.type === "shorts" && isUnPaid.value) {
		return;
	}

	let params;
	if (wstore.value.orderCol === "custom") {
		params = {
			row_count: wstore.value.customShorts.split(",").length,
			order_field: "no",
			order_values: wstore.value.customShorts ? wstore.value.customShorts : "1",
			cache_time: 5,
			user_no: userNo.value,
			type: "shorts",
			no_product: wstore.value.product == "Y" ? "" : "Y",
			only_shortform: wstore.value.shortform,
			have_product: wstore.value.product == "Y" ? "Y" : "",
		};
	} else {
		params = {
			row_count: wstore.value.limit,
			order_col: wstore.value.orderCol,
			cache_time: 5,
			user_no: userNo.value,
			type: "shorts",
			no_product: wstore.value.product == "Y" ? "" : "Y",
			only_shortform: wstore.value.shortform,
			have_product: wstore.value.product == "Y" ? "Y" : "",
		};
	}

	await store.getShortsList(params).then(async () => {
		if (props.options.type === "shorts" && props.options.isPreview === "Y" && store.shorts) {
			const previewList = store.shorts.map((p) => {
				return p.content_no;
			});

			await store.getShortsPreviewList({
				content_no: previewList.join(","),
				user_no: userNo.value,
				top_url: location.href,
				is_mobile: wstore.value.mobile,
				user_agent: navigator.userAgent,
				no_log: "Y",
			});
		}
		init_load.value = true;
		resizeRun();
	});

	// await nextTick(() => {
	// 	if (props.options.isPreview === "Y" && store.shorts) {
	// 		if (document.getElementById("short-card-list")) {
	// 			const video = document.getElementById("short-card-list").querySelectorAll("video");
	// 			video.forEach((video) => {
	// 				observer.observe(video);
	// 			});
	// 		}
	// 	}
	// });

	window.addEventListener("resize", () => {
		resizeRun();
	});
});
</script>
<style>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
</style>
<style scoped>
.l24TraConter {
	container-name: main-container;
	container-type: inline-size;
}

.shortCardItemsd {
	scroll-snap-type: x mandatory;
	position: relative;
}

.shortCardItemsd:first-child {
}

.l24 a {
	text-decoration: none;
	color: #000;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

.cardViewWrap {
	display: flex;
}

.row {
}

.wrap {
	width: 100%;
	height: 100%;
	min-height: 300px;
}

.overflow-auto {
	overflow: auto;
	width: 100%;
}

.noscroll::-webkit-scrollbar {
	display: none;
}

.noscroll {
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
}

.overflow-hidden {
	overflow: hidden;
	width: 100%;
}

.clearfix {
	clear: both;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
}

@container main-container  (max-width: 768px) {
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 10px;
	}
}

.grid-gap-1 {
	grid-gap: 10px;
}

.grid-gap-2 {
	grid-gap: 20px;
}

.grid-gap-3 {
	grid-gap: 30px;
}

.grid-gap-4 {
	grid-gap: 40px;
}

.grid-gap-5 {
	grid-gap: 50px;
}

.grid-gap-6 {
	grid-gap: 60px;
}

.grid-cols-1 {
	grid-template-columns: repeat(1, 1fr);
}

.grid-cols-2 {
	grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
	grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
	grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
	grid-template-columns: repeat(5, 1fr);
}

.grid-cols-6 {
	grid-template-columns: repeat(6, 1fr);
}

.grid-cols-7 {
	grid-template-columns: repeat(7, 1fr);
}

.grid-cols-8 {
	grid-template-columns: repeat(8, 1fr);
}

.grid-cols-9 {
	grid-template-columns: repeat(9, 1fr);
}

.grid-cols-10 {
	grid-template-columns: repeat(10, 1fr);
}

.pos_r {
	position: relative;
}

.pos_a {
	position: absolute;
}

.swiper-button-prev {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	background-image: unset !important;
	width: 40px;
	height: 40px;
	z-index: 2;
	border-radius: 100%;
	background-color: rgba(236, 233, 233, 0.83);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.swiper-button-next {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	background-image: unset !important;
	width: 40px;
	height: 40px;
	z-index: 2;
	border-radius: 100%;
	background-color: rgba(236, 233, 233, 0.83);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	content: "";
}

.hoverVst {
	display: none;
}

@media (min-width: 576px) {
	.hoverTst:hover .hoverVst {
		display: block;
	}
}

.hoverTst .swiper-button-next,
.hoverTst .swiper-button-prev {
	background-image: none !important;
}

.isProduct .swiper-button-next,
.isProduct .swiper-button-prev {
	transform: translateY(-100%);
}
</style>
