<script setup>
import {defineProps, onMounted, onUnmounted} from "vue";
import Hls from "hls.js";

const props = defineProps({
	muted: {
		type: Boolean,
		default: false,
	},
	autoplay: {
		type: Boolean,
		default: true,
	},
	hlsUrl: {
		type: String,
		required: true,
	},
	loop: {
		type: Boolean,
		default: false,
	},
	poster: {
		type: String,
		default:
			"https://tkmenfxu2702.edge.naverncp.com/profile/202312/4057e237fd97a9d94e5900d833e9bca8.png",
	},
	aspectRatio: {
		type: String,
		default: "9/16",
	},
	id: {
		type: String,
		default: "floatings",
	},
	start: {
		type: Number,
	},
	end: {
		type: Number,
	},
});

let hls = null;
let video = null;
let playUrl = null;
let firstLoad = false;

let duration = 0;

let tooShort = false;

const handleTimeUpdate = (e) => {
	if (props.loop && video) {
		if (video.duration - video.currentTime <= 0.5) {
			video.currentTime = 0;
			video.play();
		}
	}
};

onMounted(() => {
	if (props.hlsUrl) {
		playUrl = props.hlsUrl;
		if (props.start && props.end) {
			playUrl = props.hlsUrl.replace(
				"/shorts/",
				`/vodStart/${props.start}/vodEnd/${props.end}/shorts/`,
			);
		}

		hls = new Hls();
		video = document.getElementById(`l24werPlayer_player_${props.id}`);

		video.addEventListener("timeupdate", handleTimeUpdate);

		if (Hls.isSupported()) {
			hls.loadSource(playUrl);
			hls.attachMedia(video);
		} else if (video.canPlayType("application/vnd.apple.mpegurl")) {
			video.src = playUrl;
		}

		if (props.autoplay) video.play();
	}
});

onUnmounted(() => {
	if (video) {
		video.removeEventListener("timeupdate", handleTimeUpdate);
	}

	if (Hls.isSupported()) {
		hls.destroy();
		hls = null;
	} else {
		video.src = "";
	}
});
</script>

<template>
	<video
		:id="`l24werPlayer_player_${id}`"
		:autoplay="autoplay ? true : false"
		:loop="loop ? true : false"
		:muted="muted ? true : false"
		:style="{
            aspectRatio: aspectRatio,
            background: `url(${poster}) center center / cover no-repeat`,
        }"
		class="w-full h-full"
		loading="lazy"
		playsinline
		poster="https://tkmenfxu2702.edge.naverncp.com/profile/202312/4057e237fd97a9d94e5900d833e9bca8.png"
		style="object-fit: cover"
	></video>
</template>

<style scoped>
.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}
</style>
