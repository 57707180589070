<script setup>
import "animate.css";
import axios from "axios";
import UAParser from "ua-parser-js";
import { defineProps, onMounted, onUnmounted, reactive, ref } from "vue";
import UiHls from "./ui/hls.vue";

const props = defineProps({
  options: {
    type: Object,
  },
});

const element = reactive({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  isActive: false,
  round: 0,
});

const toLoad = ref(false);
const initLoad = ref(false);
const isMobile = ref(false);
const isPlay = ref(true);
const isMuted = ref(true);
const isHls = ref(false);
const isVideoReady = ref(false);
const currentXY = reactive({x: 0, y: 0});
const activeStatus = ref(null)

const wstore = ref({
  privew: false,
  innerMode: false,
  contentNo: null,
  userNo: null,
  productNo: null,
});

const setOptions = (options) => {
  initLoad.value = false;
  wstore.value = {...wstore.value, ...options};
};

defineExpose({
  setOptions,
});

let l24config = null;
let shorts = ref(null);
const getShortsInfo = async () => {
  if (wstore.value.productNo && wstore.value.userNo) {
    const res = await axios.get(`https://live24.app/shorts24/content/in_product`, {
      params: {
        product_no: wstore.value.productNo,
        user_no: wstore.value.userNo,
        top_url: location.href,
        is_mobile: isMobile.value,
        user_agent: navigator.userAgent,
      },
    });
    if (res.data.code == 200) {
      return res.data.result;
    }
  }
};

const goPlayer = () => {
  window.open(shorts.value.shorts_url);
  shorts.value = null;
};

let video = null;
const activeBtn = ref(false);
const togglePlay = () => {
  if (!activeBtn.value) return;
  document.getElementById("l24werPlayer_player_s24lod").paused
    ? document.getElementById("l24werPlayer_player_s24lod").play()
    : document.getElementById("l24werPlayer_player_s24lod").pause();

  isPlay.value = document.getElementById("l24werPlayer_player_s24lod").paused;
};

const toggleMuted = () => {
  if (!activeBtn.value) return;
	console.log( document.getElementById("l24werPlayer_player_s24lod").muted)
  document.getElementById("l24werPlayer_player_s24lod").muted
    ? (document.getElementById("l24werPlayer_player_s24lod").muted = false)
    : (document.getElementById("l24werPlayer_player_s24lod").muted = true);

	document.getElementById("l24werPlayer_player_s24lod").muted ? isMuted.value = true : isMuted.value = false;
};

let isVetical = ref(true);
let activeTimer = null;
let outActiveTimer = null;
let fadeAni = ref("animate__fadeIn");

const inActive = () => {
  isPlay.value = document.getElementById("l24werPlayer_player_s24lod").paused;
  if (!activeBtn.value) {
    fadeAni.value = "animate__fadeIn";
    activeBtn.value = true;
    clearTimeout(activeTimer);
    activeTimer = setTimeout(() => {
      outActive();
    }, 3000);
  }
};
const outActive = () => {
  fadeAni.value = "animate__fadeOut";
  clearTimeout(outActiveTimer);
  outActiveTimer = setTimeout(() => {
    activeBtn.value = false;
  }, 200);
};

const setVideoSize = () => {
  if (shorts.value.width > 0 && shorts.value.height > 0) {
    if (shorts.value.width > shorts.value.height) {
      isVetical.value = false;
      if (window.innerWidth < 600) {
        element.width = 220;
        element.height = 130;
      } else {
        element.width = 290;
        element.height = 170;
      }
    } else if (shorts.value.width === shorts.value.height) {
      if (window.innerWidth < 600) {
        element.width = 180;
        element.height = 180;
      } else {
        element.width = 220;
        element.height = 220;
      }
    } else {
      isVetical.value = true;
      if (window.innerWidth < 600) {
        element.width = 112.5;
        element.height = 200;
      } else {
        element.width = 200;
        element.height = 360;
      }
    }
  }
};

const getActiveStatus = async () => {
  const res = await axios.get('https://mapi.live24.app/pay/plugin/order/status', {
    params: {
      user_no: wstore.value.userNo,
    }
  })

  return res.data.result.list.find((item) => {
    // TODO: 리얼 배포할 때 위젯 ID 변경 해야 함
    if (item.id === 'de-ext-0001') {
      return item;
    } else {
      return null;
    }
  })
}

const setVideoPosition = async () => {
  const {etc_data} = shorts.value;

  if (!etc_data) {
    if (isMobile.value) {
      element.x = currentXY.x !== 0 ? currentXY.x : window.innerWidth - element.width - 10;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - 40;
    } else {
      element.x = currentXY.x !== 0 ? currentXY.x : window.innerWidth - element.width - 20;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - 80;
    }
    return
  }

  if (isMobile.value) {
    if (activeStatus.value && activeStatus.value.active === 'Y') {
      element.x = currentXY.x !== 0 ? currentXY.x : etc_data.x + 10;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - etc_data.y - 40;
    } else {
      element.x = currentXY.x !== 0 ? currentXY.x : window.innerWidth - element.width - etc_data.x - 10;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - etc_data.y - 40;
    }
  } else {
    if (activeStatus.value && activeStatus.value.active === 'Y') {
      element.x = currentXY.x !== 0 ? currentXY.x : etc_data.pcx + 20;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - etc_data.pcy - 80;
    } else {
      element.x = currentXY.x !== 0 ? currentXY.x : window.innerWidth - element.width - etc_data.pcx - 20;
      element.y = currentXY.y !== 0 ? currentXY.y : window.innerHeight - element.height - etc_data.pcy - 80;
    }
  }
};

let initRunTs = false;
let initRunTsTimer = null;
let initRunWh = false;
let isSlowSite = false;
const initRun = () => {
  if (initRunTs) {
    return;
  }
  initRunWh = true;
  let initTimeSec = 100;
  if (isSlowSite) {
    initTimeSec = 400;
  }
  try {
    video = document.getElementById("l24werPlayer_player_s24lod");
    try {
      if (shorts.value.width != video.videoWidth && video.videoWidth > 0) {
        shorts.value.width = video.videoWidth;
      }
      if (shorts.value.height != video.videoHeight && video.videoHeight > 0) {
        shorts.value.height = video.videoHeight;
      }

      setVideoSize();
      setVideoPosition();

      window.addEventListener('scroll', setVideoPosition)

      if (video.videoWidth > 0 && video.videoHeight > 0) {
      } else {
        initRunTsTimer = setTimeout(() => {
          initRun();
        }, initTimeSec);
        return;
      }
    } catch (e) {
    }
    video.removeEventListener("play", () => {
    });
    video.removeEventListener("pause", () => {
    });
    video.removeEventListener("volumechange", () => {
    });

    video.addEventListener("play", () => {
      isPlay.value = true;
    });
    video.addEventListener("pause", () => {
      isPlay.value = false;
    });
    video.addEventListener("volumechange", () => {
      if (video.muted) {
        isMuted.value = true;
      } else {
        isMuted.value = false;
      }
    });

    toLoad.value = false;
    // setVideoSize();

    if (initRunWh && initRunWh) {
      initRunTs = true;
      setTimeout(() => {
        toLoad.value = true;
        isVideoReady.value = true;
      }, initTimeSec);
    } else {
      initRunTsTimer = setTimeout(() => {
        initRun();
      }, initTimeSec);
    }
  } catch (e) {
    initRunTsTimer = setTimeout(() => {
      initRun();
    }, initTimeSec);
  }
};

onMounted(async () => {
  if (window.l24idtoLoad) return;
  if (window.l24idtoLoaTs) return;
  if (window.l24ToFastStart) return;

  console.log("run l24 floating");

  wstore.value = {...wstore.value, ...props.options};

  let parser = new UAParser(navigator.userAgent);
  if (parser.getDevice().type === "mobile") {
    isMobile.value = true;
  }

  if (top === self) {
    shorts.value = await getShortsInfo();
    activeStatus.value = await getActiveStatus();

    if (shorts.value) {
      /*if (location.host.indexOf("tedwomanworld.co.kr") > -1) {

      } else {
        initRun();
      }*/

      isSlowSite = true;
      setTimeout(() => {
        initRun();
      }, 600);

      //마이하우스 사이트 버그로 인한 조치
      if (location.host.indexOf("myhouse.co.kr") > -1) {
        isHls.value = true;
      }
    }

    if (!window.l24idtoLoad) {
      toLoad.value = true;
    }
  } else {
    console.log("Shorts24App : iframe에서는 동작하지 않습니다.");
  }
});

onUnmounted(() => {
  try {
    window.removeEventListener('scroll', setVideoPosition)
    video.removeEventListener("play", () => {
    });
    video.removeEventListener("pause", () => {
    });
    video.removeEventListener("volumechange", () => {
    });
  } catch (e) {
  }
});

const nope = () => {
  currentXY.x = element.x;
  currentXY.y = element.y;
};
</script>

<template>
  <div v-if="shorts && toLoad" class="s24Wrap">
    <div class="s24Float" style="--animate-duration: 0.2s; pointer-events: none;">
      <DraggableResizableVue
        v-model:active="element.isActive" v-model:h="element.height"
        v-model:w="element.width"
        v-model:x="element.x"
        v-model:y="element.y"
        :draggable="true"
        :maxHeight="360"
        :maxWidth="200"
        :minHeight="270"
        :minWidth="120"
        :resizable="false"
        :style="{
					borderRadius: element.round > -1 ? element.round + 'px' : '15px',
				}"
        style="
					border: 0;
					overflow: hidden;
					filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
					pointer-events: auto;
				"
        @click="inActive"
        @dragstop="nope"
        @mouseleave="outActive"
      >
        <div v-if="activeBtn" :class="fadeAni" class="s24btnWarp animate__animated">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <button class="nostyle pointer" style="padding: 8px" type="button" @click="goPlayer">
                <svg
                  fill="#f2f2f2"
                  height="22"
                  viewBox="0 0 256 256"
                  width="22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M192,72V216a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8H184A8,8,0,0,1,192,72Zm24-40H72a8,8,0,0,0,0,16H208V184a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <button class="nostyle pointer" style="padding: 8px" type="button" @click="shorts = null">
                <svg
                  fill="#ffffff"
                  height="22"
                  viewBox="0 0 256 256"
                  width="22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            :style="{
							gap: isVetical ? '15px' : '5px',
							paddingBottom: isVetical ? '0px' : '10px',
						}"
            class="flex-1"
            style="display: flex; flex-direction: column; align-items: center; justify-content: center"
          >
            <div>
              <button class="nostyle pointer" type="button" @click="togglePlay">
                <template v-if="isPlay">
                  <svg
                    fill="#f2f2f2"
                    height="32"
                    viewBox="0 0 256 256"
                    width="32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M240,128a15.74,15.74,0,0,1-7.6,13.51L88.32,229.65a16,16,0,0,1-16.2.3A15.86,15.86,0,0,1,64,216.13V39.87a15.86,15.86,0,0,1,8.12-13.82,16,16,0,0,1,16.2.3L232.4,114.49A15.74,15.74,0,0,1,240,128Z"
                    ></path>
                  </svg>
                </template>
                <template v-else>
                  <svg
                    fill="#f2f2f2"
                    height="32"
                    viewBox="0 0 256 256"
                    width="32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M216,48V208a16,16,0,0,1-16,16H160a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h40A16,16,0,0,1,216,48ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Z"
                    ></path>
                  </svg>
                </template>
              </button>
            </div>

            <div>
              <button
                :style="isMobile ? 'zoom:0.8' : 'zoom:1'"
                class="nostyle pointer"
                style="
					padding: 8px 12px;
					color: #fff;
					background-color: rgba(75, 75, 75, 0.87);
					border-radius: 50px;
					font-size: 12px;
				"
                type="button"
                @click="toggleMuted"
              >
                {{ isMuted ? "소리켜기" : "음소거" }}
              </button>
            </div>
          </div>
        </div>

        <div
          :style="{
						opacity: isVideoReady ? 1 : 0,
					}"
          style="position: relative; width: 100%; height: 100%"
        >
          <div
            style="position: absolute; top: 0; left: 0; right: 0; z-index: 3; width: 100%; height: 100%"
          ></div>

          <UiHls
            v-if="isHls"
            :id="'s24lod'"
            :autoplay="true"
            :hlsUrl="shorts.hlsPlayUrl"
            :loop="true"
            :muted="true"
            :style="{ objectFit: isVetical ? 'cover' : 'cover' }"
            style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 2"
          />
          <video
            v-else
            id="l24werPlayer_player_s24lod"
            :src="shorts.endpointurl"
            :style="{ objectFit: isVetical ? 'cover' : 'cover' }"
            autoplay
            class="s24VideoPlayer"
            loop
            muted
            playsinline
            preload="metadata"
            style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 2"
          />
        </div>
      </DraggableResizableVue>
    </div>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.nostyle {
  border: none;
  background: none;
}

.flex-1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.s24btnWarp {
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: move;
}

.s24Wrap {
  position: relative;
}

.s24Float video.s24VideoPlayer {
  border-radius: 5px;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  margin: 0 !important;
  position: relative !important;
  left: 0 !important;
}

.s24Float {
  position: fixed;
  inset: 0;
  z-index: 999;
}

.closeBtnWarp {
  position: absolute;
  right: -12px;
  top: -14px;
  z-index: 99;
  padding: 5px;
  cursor: pointer;
}

.closeBtn {
  background-color: #000;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .s24Float {
    margin-right: 15px;
    margin-bottom: 80px;
    max-width: 120px;
  }
}

@media (min-width: 768px) {
  .s24Float {
    margin-right: 30px;
    margin-bottom: 80px;
    max-width: 180px;
  }
}
</style>
