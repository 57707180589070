<script setup>
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import { useL24Store } from "../store/l24";
import UAParser from "ua-parser-js";

const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

const initLoad = ref(false);
const isMobile = ref(false);

const playerType = ref("");

const pageIfrUrl = ref("");
const playerMode = ref("to_player"); //float_player
const windowState = ref({
	width: 0,
	height: 0,
});
const userKey = ref(null);
if (sessionStorage.getItem("USER_KEY")) {
	userKey.value = sessionStorage.getItem("USER_KEY");
}

const wstore = ref({
	privew: false,
	innerMode: false,
	postNo: null,
	targetDiv: "",
	loginPage: "",
	loginType: "",
	idTemplate: "",
	type: "",
	ui: "auto",
});

const go_link = (url) => {
	pageIfrUrl.value = url;
};

const setOptions = (options) => {
	initLoad.value = false;

	wstore.value = { ...wstore.value, ...options };

	if (options.type == "all") {
		playerType.value = "";
	} else {
		playerType.value = options.type;
	}

	console.log("!!options", options, playerType.value);

	setTimeout(() => {
		ifrUrlMake();
	}, 300);
};

defineExpose({
	setOptions,
});

let l24config = null;
const ifrUrl = ref(null);
const selfLogin = ref(false);
const nouser = ref(false);

const getQueryVariable = (variable) => {
	try {
		let query = null;
		if (wstore.value.search) {
			query = wstore.value.search.substring(1);
		} else {
			if (location.search) {
				query = location.search.substring(1);
			} else {
				query = top.location.search.substring(1);
			}
		}
		let vars = query.split("&");
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return "";
	} catch (e) {
		return "";
	}
};
const ifrUrlMake = () => {
	try {
		if (sessionStorage.getItem("USER_KEY")) {
			userKey.value = sessionStorage.getItem("USER_KEY");
		}
		if (window.user_key) {
			userKey.value = window.user_key;
		}
	} catch (e) {}

	if (!userKey.value) {
		userKey.value = "nouser";
	}

	if (nouser.value) {
		userKey.value = "nouser";
	}
	let tmpIfrUrl = ifrUrl.value;
	ifrUrl.value = "";

	let playerUri = "https://player-v4.live24.app";
	if (process.env.NODE_ENV !== "production") {
		//playerUri = "http://localhost:3000";
	}

	try {
		if (!wstore.value.search) {
			wstore.value.search = top.location.search;
		}
	} catch (e) {}

	try {
		tmpIfrUrl =
			`${playerUri}/player/${l24config.user_no}?from=widget&activePip=${
				windowState.value.width < 728 || isMobile.value ? true : ""
			}&key=${userKey.value}&zoom=${wstore.value.privew ? 0.8 : 1}&self_login=${
				selfLogin.value ? true : ""
			}&type=${playerType.value}&ui=${wstore.value.ui == "mobile" ? "mobile" : ""}&s24_in_player=${
				wstore.value.s24_in_player == "Y" ? "Y" : ""
			}` +
			`&content_no=${getQueryVariable("content_no")}` +
			`&isstudio=${getQueryVariable("isstudio")}` +
			`&nox=${getQueryVariable("nox")}` +
			`&muted=${getQueryVariable("muted")}` +
			`&tr_no=${getQueryVariable("tr_no")}`;
	} catch (e) {}
	setTimeout(() => {
		ifrUrl.value = tmpIfrUrl;

		initLoad.value = true;
	}, 200);
};

let isShopByLogin = false;
const getShopbyMemberId = async () => {
	let memberId = null;
	try {
		if (isShopByLogin) return;
		memberId = sb.profile.memberId;
		await l24App.login({
			loginPageUrl: `/pages/sign-in/sign-in.html?from=${location.href}`,
			userid: memberId,
		});
		sessionStorage.setItem("USERID", memberId);
		console.log("shoby", "login : ", memberId);
		ifrUrlMake();
		isShopByLogin = true;
	} catch (e) {
		console.log("shoby", "not login");
		l24App.login({
			loginPageUrl: `/pages/sign-in/sign-in.html?from=${location.href}`,
		});
	}
	return memberId;
};

const loginSetting = async (ws) => {
	let memberId = null;
	if (ws.loginType == "shopby") {
		getShopbyMemberId();
		setTimeout(() => {
			getShopbyMemberId();
			setTimeout(() => {
				getShopbyMemberId();
			}, 1000);
		}, 1000);

		selfLogin.value = true;

		ifrUrlMake();
	} else if (ws.loginType == "cafe24") {
		try {
			CAFE24API.init({
				client_id: "QFf0CgssYa4YTLAyyi7hJC",
				version: "2022-06-01",
			});
		} catch (e) {}
		setTimeout(async () => {
			try {
				sessionStorage.setItem("MALL_ID", CAFE24API.MALL_ID);
				sessionStorage.setItem("SHOP_NO", CAFE24API.SHOP_NO);

				await CAFE24API.getCustomerInfo(async (err, res) => {
					if (err) {
						//console.log(err);
						sessionStorage.removeItem("USER_KEY");
						sessionStorage.removeItem("USER_MEMBER_ID");
						sessionStorage.removeItem("USER_MEMBER_GROUP_ID");
						sessionStorage.removeItem("USER_MEMBER_GROUP_NAME");
					} else {
						sessionStorage.setItem("USER_MEMBER_ID", res.customer.member_id);
						sessionStorage.setItem("USER_MEMBER_GROUP_ID", res.customer.group_no);
						sessionStorage.setItem("USER_MEMBER_GROUP_NAME", res.customer.group_name);
					}

					await l24App.login({
						loginPageUrl: "/member/login.html",
						userid: res?.customer?.member_id,
					});

					setTimeout(() => {
						ifrUrlMake();
					}, 300);
				});
			} catch (e) {
				//initLoad.value = true;
			}
		}, 500);
	} else {
		selfLogin.value = true;
		setTimeout(async () => {
			if (ws.idTemplate == "<!--/user_id/-->") {
				nouser.value = true;
			}

			if (ws.idTemplate == "{=gSess.memId}") {
				nouser.value = true;
			}

			if (ws.idTemplate == "{회원아이디}") {
				nouser.value = true;
			}

			if (ws.idTemplate == "{userInfo.userid}") {
				nouser.value = true;
			}
			if (ws.idTemplate == "") {
				nouser.value = true;
			}

			if (!nouser.value) {
				await l24App.login({
					loginPageUrl: ws.loginPage,
					userid: ws.idTemplate,
				});
			}
		}, 200);
	}

	setTimeout(() => {
		initLoad.value = true;
	}, 2500);
};

const isNoScrollBody = false;
const onMouseIn = () => {
	if (document.body.style.overflow == "hidden" || document.body.style.overflowY == "hidden") {
		isNoScrollBody = true;
	} else {
		document.body.style.overflowY = "hidden";
		document.getElementsByTagName("html")[0].style.overflowY = "hidden";
	}
};

const onMouseOut = () => {
	if (!isNoScrollBody) {
		document.body.style.overflowY = "auto";
		document.getElementsByTagName("html")[0].style.overflowY = "auto";
	}
};

const onWheel = (e) => {
	console.log("1111 onWheel");
	/*	e.preventDefault();
	e.stopPropagation();*/
};

onMounted(async () => {
	l24config = await store.getL24State();

	wstore.value = { ...wstore.value, ...props.options };

	if (wstore.value.loginType != "cafe24") {
		selfLogin.value = true;

		if (wstore.value.loginPage) {
			//영카트를 위한 처리
			if (wstore.value.loginPage == "/bbs/login.php") {
				if (self !== top) {
					window.l24LoginPageUrl =
						wstore.value.loginPage +
						`?url=${encodeURIComponent(top.location.pathname + top.location.search)}`;
				} else {
					window.l24LoginPageUrl =
						wstore.value.loginPage + `?url=${encodeURIComponent(location.pathname + location.search)}`;
				}
			} else {
				window.l24LoginPageUrl = wstore.value.loginPage;
			}
		}
	} else {
		wstore.value.loginPage = "/member/login.html";
		wstore.value.idTemplate = "자동연동";
		wstore.value.livePlayerLink = "자동연동";
		wstore.value.search = location.search;
	}

	await loginSetting(wstore.value);

	if (wstore.value.type == "all") {
		playerType.value = "";
	} else {
		playerType.value = wstore.value.type;
	}

	let parser = new UAParser(navigator.userAgent);
	if (parser.getDevice().type === "mobile") {
		isMobile.value = true;
	}

	windowState.value.height = window.innerHeight;
	windowState.value.width = window.innerWidth;
	window.addEventListener("resize", () => {
		windowState.value.height = window.innerHeight;
		windowState.value.width = window.innerWidth;
	});

	document.body.classList.add("hidden-scrollbar");

	setTimeout(() => {
		setTimeout(() => {
			ifrUrlMake();
		}, 300);
		window.addEventListener("message", (e) => {
			//go_c24_href
			if (e.data.action === "go_c24_href") {
				playerMode.value = "float_player";
				setTimeout(function () {
					go_link(e.data.go_url);
				}, 1);
			}

			if (e.data.action === "go_c24_href_nch") {
				setTimeout(function () {
					go_link(e.data.go_url);
				}, 1);
			}
			if (e.data.action === "go_c24_href_top") {
				if (e.data.go_url.indexOf("/member/login.html?returnUrl=") > -1) {
					let newUrl = "/member/login.html?returnUrl=" + top.location.pathname + top.location.search;
					top.location.href = newUrl;
				} else {
					top.location.href = e.data.go_url;
				}
			}
			if (e.data.action === "to_mode") {
				if (e.data.set_mode == "window") {
					playerMode.value = "to_player";
				} else {
					playerMode.value = e.data.set_mode;
				}
			}
		});
	}, 300);
});

onUnmounted(() => {
	window.removeEventListener("resize", () => {});
	window.removeEventListener("message", () => {});
});
</script>

<template>
	<div
		class="l24PlayerWarpFixed autoheight"
		:class="{
			pos_f: !wstore.privew && !wstore.targetDiv,
			pos_a: wstore.privew && !wstore.innerMode,
		}"
		v-if="initLoad"
		:style="{
			height: wstore.privew
				? wstore.height
					? wstore.height + 'px'
					: wstore.ui == 'mobile'
					? '620px'
					: '760px'
				: windowState.height + 'px',
		}"
	>
		<div class="l24PlayerWarpRe">
			<iframe
				:src="pageIfrUrl"
				class="l24ifrPageWarp"
				frameborder="0"
				allowfullscreen
				allowtransparency="true"
				allow="clipboard-read; clipboard-write; fullscreen; picture-in-picture"
			></iframe>
			<div
				class="l24ifrWarp"
				@mouseenter="onMouseIn"
				@mouseleave="onMouseOut"
				@wheel="onWheel"
				:class="playerMode"
			>
				<iframe
					class="ifrs"
					:src="ifrUrl"
					frameborder="0"
					allowfullscreen
					allowtransparency="true"
					:style="{
						maxWidth:
							wstore.privew && wstore.ui == 'mobile'
								? wstore.height
									? wstore.height / 2 + 'px'
									: '380px'
								: 'auto',
					}"
					:class="{
						asp_9_16: wstore.privew && wstore.ui == 'mobile',
					}"
					allow="clipboard-read; clipboard-write; fullscreen; picture-in-picture"
				></iframe>
			</div>
		</div>
	</div>
</template>

<style>
.no-scroll-bodyts {
	overflow: hidden;
}
.hidden-scrollbar::-webkit-scrollbar {
	display: none;
}
</style>
<style scoped>
.pos_f {
	position: fixed;
}
.pos_a {
	position: absolute;
}

.asp_9_16 {
	aspect-ratio: 9/16;
	margin: auto;
}
.l24PlayerWarpFixed {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.l24PlayerWarpRe {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #000;
}

.l24ifrWarp {
	position: absolute;
	display: flex;
	justify-items: center;
	z-index: 10;
}

.ifrs {
	width: 100%;
	height: 100%;
}
.l24ifrWarp.to_player,
.l24ifrWarp.window {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@media screen and (max-width: 768px) {
	.l24ifrWarp.float_player {
		position: fixed;
		bottom: 70px;
		right: 10px;
		width: 100px;
		aspect-ratio: 9/14;
		overflow: hidden;

		border-radius: 5px;
		box-shadow: -9pt 12px 21px -0.052083333in rgba(25, 42, 70, 0.13);
		-webkit-transition: all 0.1s ease;
		-moz-transition: all 0.1s ease;
		-o-transition: all 0.1s ease;
		transition: all 0.1s ease;
	}
}

@media screen and (min-width: 768px) {
	.l24ifrWarp.float_player {
		position: fixed;
		bottom: 70px;
		right: 30px;
		width: 200px;
		aspect-ratio: 9/14;
		overflow: hidden;

		border-radius: 10px;
		box-shadow: -9pt 12px 21px -0.052083333in rgba(25, 42, 70, 0.13);
		-webkit-transition: all 0.1s ease;
		-moz-transition: all 0.1s ease;
		-o-transition: all 0.1s ease;
		transition: all 0.1s ease;
	}
}

.l24ifrPageWarp {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #fff;
}
</style>
